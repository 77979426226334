import 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContentBlocks from '../sanity/ContentBlocks';
import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import HomeHero from '../components/home/HomeHero';
import CategoryView from '../components/category/CategoryView';
// import CollectionView from '../components/collection/CollectionView';

const Category = ({ data }) => {
  const { category, collections } = data;
  return (
    <Layout>
      <Seo
        title={category.title}
        description={category.description}
        image={
          category.image && category.image.asset && category.image.asset.url
        }
      />
      <HomeHero
        title={category.title}
        fluid={
          category.image &&
          category.image.asset &&
          category.image.asset.gatsbyImageData
        }
        description={category.description}
      />
      <CategoryView data={collections.nodes} />
      {category.contentBlocks &&
        category.contentBlocks.map((block, idx) =>
          React.createElement(ContentBlocks(block._type), {
            idx,
            key: block._key,
            ...block,
          }),
        )}
    </Layout>
  );
};

Category.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Category;

export const query = graphql`
  query ($slug: String!) {
    category: sanityCategory(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
      description
      image {
        asset {
          gatsbyImageData
          url
        }
      }
      contentBlocks: _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
    collections: allSanityCollection(
      sort: { fields: order, order: ASC }
      filter: { category: { slug: { current: { eq: $slug } } } }
    ) {
      nodes {
        _id
        title
        slug {
          current
        }
        brand {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        body
        image {
          asset {
            gatsbyImageData
          }
        }
        category {
          _id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;
