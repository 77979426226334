import React from 'react';
import 'twin.macro';

import { Container } from '../atoms';
import SanityImage from '../global/SanityImage';
import { Information } from '../elements';

const CategoryView = ({ data }) => {
  if (!data) return null;
  return (
    <Container tw="bg-slate">
      {data.map((item, i) => {
        if (!item) return null;
        return (
          <div
            tw="py-10 grid grid-cols-1  md:grid-cols-2 justify-center items-center"
            key={item._id || i}>
            {item.image && item.image.asset && (
              <SanityImage fluid={item.image.asset.gatsbyImageData} />
            )}
            <div tw="p-6 md:p-14 bg-white">
              <Information
                heading={item.title}
                description={item && item.body && item.body.slice(0, 190)}
                buttonText="VIEW RANGE"
                to={`/collection/${item.slug.current}`}
                brandImage={
                  item.brand &&
                  item.brand.image &&
                  item.brand.image.asset.gatsbyImageData
                }
              />
            </div>
          </div>
        );
      })}
    </Container>
  );
};

export default CategoryView;
